import React from 'react'
import Footer from '../Component/Footer'
import NavbarComp from '../Component/NavbarComp'
import RentContent from '../Component/Rent/RentContent'

const Rent = () => {
  return (
    <>
      <NavbarComp />
      <RentContent/>
      <Footer />
    </>
  )
}

export default Rent