import '../css/common.css'
const About = () => {
    return(
        <div className="belowslider">
            {/* <div className="container py-2">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <h1 className="text-center text-white">
                            About
                        </h1>
                        <p className='text-white text-center py-2'>We are providing warehouses (godowns) in Bhiwandi. We have been in this business for long time, so we know what is the best solution to your problems. Our warehouses are located in a secured area, so there is no risk of theft or damage to your goods. Our godowns are well equipped with modern facilities that meet all international standards, so you can be assured about the quality of our services.</p>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div> */}
        </div>
    )
}
export default About;