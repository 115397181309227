
import { IoLocationSharp, IoLogoWhatsapp, } from "react-icons/io5";
import {HiOutlineMail} from "react-icons/hi"
import { IoIosCall } from "react-icons/io";
import '../css/common.css'
import contactImg from '../../Assets/img/contact.png'
import { Image } from "react-bootstrap";
const ContactContent = () => {
    return(
        <div className="container">
            <div className="row">
                <h2>Contact Us</h2>
                <div className="col-lg-6">
                    <Image src={contactImg} className="img-fluid"/>
                    </div>
                <div className="col-lg-6">
                <h3>Perfect Finance Solutions</h3>
                        <div className='d-flex'>
                            <h2 className='text-dark'><IoLocationSharp fontSize={'30px'} color="#007bff"/></h2>
                            <p className='text-dark'>202, Varala Niwas, Second Floor,  Opposite Manoj Katekar Bunglow,  Bhiwandi - 421305. Dist Thane, Maharashtra. India</p></div>
                        <div className='d-flex'>
                            <h2 className='text-dark'><IoIosCall fontSize={'30px'} color="#007bff"/></h2>
                            <a href='tel:9960447972' className='link text-dark' style={{paddingTop:10}}>+91 9960447972</a>
                        </div>
                        <div className='d-flex'>
                            <h2 className='text-dark'><HiOutlineMail fontSize={'30px'} color="#c71610" /></h2>
                            <a href='mailto:mutkiri.shankar@gmail.com' className='link text-dark' style={{paddingTop:10}}>mutkiri.shankar@gmail.com</a>
                        </div>
                        <div className='d-flex'>
                            <h2 className='text-dark'><IoLogoWhatsapp fontSize={'30px'} color="#25D366" /></h2>
                            <a href='https://api.whatsapp.com/send?phone=919960447972&text=Hi' className='link text-dark' style={{paddingTop:10}}>+91 9960447972</a>
                        </div>
                    </div>
            </div>
        </div>
    )
}
export default ContactContent;