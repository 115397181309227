import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { IoLogoWhatsapp } from "react-icons/io";
function ProductCard(props) {
  return (
    <Card style={{marginBottom:20}}>
      <Card.Img variant="top" src={props.banner} />
      <Card.Body style={{justifyContent:'space-between'}}>
        <Card.Title>{props.title}</Card.Title>
        <Card.Subtitle>{props.subtitle}</Card.Subtitle>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
         <div className="d-grid gap-2 py-2">
        <Button variant="warning" size="lg" href={`https://api.whatsapp.com/send?phone=919960447972&text=Hi,%20I%20want%20to%20Know%20about%20${props.title}`}><IoLogoWhatsapp /> Ask Now</Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProductCard;