import React from 'react'
import Footer from '../Component/Footer'
import NavbarComp from '../Component/NavbarComp'
import SellContent from '../Component/Sell/SellContent'

const Sell = () => {
  return (
    <>
      <NavbarComp />
      <SellContent/>
      <Footer />
    </>
  )
}

export default Sell