import React from 'react'

const Footer = () => {
  return (
    <>
      <div className='py-2' style={{ backgroundColor: '#40474F' }}>
        <div className='container'>
          <div className='row text-center'>
            <div className="col-lg-12"><p className='text-white'>© 2023 99godowns.com || Designed & Developed By <a href='https://www.facebook.com/vivekwebinc' target={'_blank'} rel='noreferrer' style={{ color: '#F25757', textAlign: 'center', textDecoration: 'none' }}>Vivek Web</a></p></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer