import React from 'react'
import Footer from '../Component/Footer'
import NavbarComp from '../Component/NavbarComp'
import SpaceSharingContent from '../Component/SpaceSharing/SpaceSharingContent'

const SpaceSharing = () => {
  return (
    <>
      <NavbarComp />
      <SpaceSharingContent/>
      <Footer />
    </>
  )
}

export default SpaceSharing