import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from './Pages/About'
import Buy from './Pages/Buy'
import Contact from './Pages/Contact'
import Home from './Pages/Home'
import Rent from './Pages/Rent'
import Sell from './Pages/Sell'
import Services from './Pages/Services'
import SpaceSharing from './Pages/SpaceSharing'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/Buy' element={<Buy />} />
        <Route path='/Sell' element={<Sell />} />
        <Route path='/Rent' element={<Rent />} />
        <Route path='/SpaceSharing' element={<SpaceSharing />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App