import { useRef, useState } from "react";

const SellContent = () => {
    const form = useRef();
    const [visitorName, setVisitorName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [visitorMobile, setVisitorMobile] = useState('');
    const [visitorEmail, setVisitorEmail] = useState('');
    const [visitorMessage, setVisitorMessage] = useState('');
    const sendWhatsApp = (e) => {
      e.preventDefault();
      window.location.href = `https://api.whatsapp.com/send?phone=919960447972&text=Name:%20${visitorName}%20Company:%20${companyName}%20Mobile:%20${visitorMobile}%20Email:%20${visitorEmail}%20Godown%20Details:%20${visitorMessage}`
      console.log('pressed')
    };
    return (
        <div className="container">
            <div className="row py-4">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                            <form className="form" ref={form} onSubmit={sendWhatsApp}>
                  <div className="form-group">
                    <h2>Sell</h2>
                    <hr />
                  </div>
                  <div className="form-group mb-2">
                    <input type='text' className='form-control' autoComplete='off' onChange={(e)=>setVisitorName(e.target.value)} required={true} placeholder="Name" />
                  </div>
                  <div className="form-group mb-2">
                    <input type='text' className='form-control' autoComplete='off' onChange={(e)=>setCompanyName(e.target.value)} required={true} placeholder="Company Name" />
                  </div>
                  <div className="form-group mb-2">
                    <input type='number' className='form-control' autoComplete='off' onChange={(e)=>setVisitorMobile(e.target.value)} required={true} placeholder="Mobile Number" />
                  </div>
                  <div className="form-group mb-2">
                    <input type='email' className='form-control' autoComplete='off' onChange={(e)=>setVisitorEmail(e.target.value)} required={true} placeholder="Email ID" />
                  </div>
                  <div className="form-group mb-2">
                    <textarea type='text' className='form-control r' autoComplete='off' onChange={(e)=>setVisitorMessage(e.target.value)} required placeholder="Your Godown Details" />
                  </div>
                  <input className="btn btn-primary m-1" type="submit" value="Send" />

                </form>
                </div>
                <div className="col-lg-2"></div>
            </div>
        </div>
    )
}
export default SellContent;