import React from 'react'
import AboutContent from '../Component/About/Content'
import Footer from '../Component/Footer'
import NavbarComp from '../Component/NavbarComp'

const About = () => {
  return (
    <>
      <NavbarComp />
      <AboutContent/>
      <Footer />
    </>
  )
}

export default About